<template>
  <v-row no-gutters  justify="center" :class="getStyle">
    <v-dialog v-model="dialog" max-width="480" @keydown.esc="confirmModal(false)">
      <template v-if="showButton" v-slot:activator="{ on }">
        <v-btn :color="color" :block="block" :small="small" :large="large" :tile="tile" :icon="iconBtn" :fab="fab" :class="background ? 'btn-hover' : ''" :outlined="outlined" :text="text" v-on="on" @click.stop="" :style="minWidth ? 'min-width:' + minWidth + '!important;' : ''">
          <v-icon :small="small" v-if="showIcon">{{ icon }}</v-icon>
          <slot name="buttonName">
          </slot>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline" :class="headerColor">
          <slot name="header"/>
        </v-card-title>
        <v-divider v-if="divider"/>
        <v-card-text class="subtitle-2 font-weight-light grey--text text--darken-2 pt-2 pb-3">
          <slot name="content"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :color="agreeColor" text @click="confirmModal(true)">
            <slot name="agree">Вилучити</slot>
          </v-btn>
          <v-btn :color="cancelColor" text @click="confirmModal(false)">
            <slot name="cancel">Відмінити</slot>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
      name: "confirmDialog",
      props: {
          item: null,
          index: null,
          tile: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          large: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          iconBtn: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          parentDialog: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          useParentDialog: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          stop: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          small: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          block: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          fab: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          outlined: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          text: {
              type: Boolean,
              default() {
                  return true;
              }
          },
          color: {
              type: String,
              default() {
                  return 'primary';
              }
          },
          icon: {
              type: String,
              default() {
                  return 'mdi-account-outline';
              }
          },
          left: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          showButton: {
              type: Boolean,
              default() {
                  return true;
              }
          },
          showIcon: {
              type: Boolean,
              default() {
                  return true;
              }
          },
          background: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          minWidth: {
              type: String,
              default() {
                  return '';
              }
          },
          divider: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          headerColor: {
              type: String,
              default() {
                  return '';
              }
          },
          align: {
              type: String,
              default() {
                  return '';
              }
          },
          agreeColor: {
              type: String,
              default() {
                  return 'red darken-1'
              }
          },
        cancelColor: {
          type: String,
          default() {
            return 'success'
          }
        }
      },
      model: {
          prop: 'parentDialog',
          event: 'change'
      },
      computed: {
          dialog: {
            get: function () {
                if (this.useParentDialog) {
                    return this.parentDialog
                } else {
                    return this.childDialog
                }
            },
            set: function (value) {
                if (this.useParentDialog) {
                    this.$emit('change', value)
                } else {
                    this.childDialog = value
                }
            }
          },
          getStyle() {
              if (this.align) {
                  return 'align-layout-' + this.align;
              } else {
                  return '';
              }
          },
      },
  //     if (this.useParentDialog) {
  //     return this.parentDialog
  // } else {
  //     return this.childDialog
  // }
      data() {
          return {
              childDialog: false
          }
      },
      methods: {
          confirmModal(question) {
              const confirm = {
                  question,
                  item: this.item,
                  index: this.index,
              };
              this.dialog = false;
              this.$emit('confirmModal', confirm)
          },
      }
  }
</script>

<style scoped>
  .align-layout-start {
    justify-content: start !important;
  }
  .align-layout-end {
    justify-content: end !important;
  }
  .align-layout-center {
    justify-content: center !important;
  }
  .btn-hover {
    background: rgba(25, 118, 210, 0.1);
  }
</style>
